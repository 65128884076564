import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Dcontent from "./components/dashboard/content/Dcontent";

const Login = lazy(() => import("./pages/login/Login"));

const Dashboard = lazy(() => import("./dashboard/home/Home"));

const Portfolio = lazy(() => import('./dashboard/portfolio/Portfolio'));
const Package = lazy(() => import("./dashboard/package/Package"));
const Users = lazy(() => import("./dashboard/users/Users"));
const Profile = lazy(() => import("./dashboard/users/Profile"));
const Orders = lazy(() => import("./dashboard/orders/Orders"));
const Testimonial = lazy(() => import('./dashboard/testimonial/Testimonial'));
const Invoice = lazy(() => import('./dashboard/invoice/Invoice'));

function App() {
  return (
    <Suspense fallback={'Loading...'}>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dcontent />}>
          <Route index element={<Dashboard />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="packages" element={<Package />} />
          <Route path="users" element={<Users />} />
          <Route path="profile/:id" element={<Profile />} />
          <Route path="queries" element={<Orders />} />
          <Route path="testimonial" element={<Testimonial />} />
          <Route path="invoice" element={<Invoice />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
