const dashboardNavData = [
  {
    navItemContainer: 'My Dashboard',
    navItems: [{ url: '/dashboard', text: 'Dashboard', dropdown: false }],
  },

  {
    navItemContainer: 'User',
    navItems: [{ url: 'users', text: 'All Users', dropdown: false }],
  },
  {
    navItemContainer: 'Shop & Orders',
    navItems: [
      {
        url: 'portfolio',
        text: 'Portfolio',
        dropdown: false,
      },
      {
        url: 'packages',
        text: 'Packages',
        dropdown: false,
      },
      {
        url: 'testimonial',
        text: 'Testimonials',
        dropdown: false,
      },
      {
        url: 'queries',
        text: 'Queries',
        dropdown: false,
      },
      {
        url: 'invoice',
        text: 'Invoices',
        dropdown: false,
      },
    ],
  },
];

export default dashboardNavData;
